<!--微信端订阅首页-->
<template>
  <div class="search-filter">
    <el-select v-model="searchFilter.Y" :disabled="loading" :class="{ 'is-active': searchFilter.Y }" placeholder="年份"
      clearable :popper-append-to-body="false" popper-class="filter-year" @change="onFilter">
      <el-option v-for="option, index in filterYOptions" :key="index" :label="option" :value="option"></el-option>
    </el-select>
    <el-select v-model="searchFilter.S" :disabled="loading" :class="{ 'is-active': searchFilter.S }" placeholder="类型"
      clearable :popper-append-to-body="false" @change="onFilter">
      <el-option v-for="option, index in filterSOptions" :key="index" :label="option" :value="option"></el-option>
    </el-select>
    <el-select v-model="searchFilter.CF" :disabled="loading" :class="{ 'is-active': searchFilter.CF }" placeholder="中央"
      clearable :popper-append-to-body="false" @change="onFilter">
      <el-option v-for="option, index in filterCFOptions" :key="index" :label="option" :value="option"></el-option>
    </el-select>
    <el-select v-model="searchFilter.PR" :disabled="loading" :class="{ 'is-active': searchFilter.PR }" placeholder="地方"
      clearable :popper-append-to-body="false" @change="onFilter">
      <el-option v-for="option, index in filterPROptions" :key="index" :label="option" :value="option"></el-option>
    </el-select>
  </div>
</template>
<script>
export default {
  name: "filter-bar",
  props: {
    cluster: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false
    },
  },
  data () {
    return {
      searchFilter: {
        Y: '',
        CF: '',
        S: '',
        PR: ''
      },
    };
  },
  computed: {
    filterYOptions () {
      return this.cluster?.filter(item => item.facetname === 'date')[0]?.groups.map(group => group.groupname)
    },
    filterCFOptions () {
      return this.cluster?.filter(item => item.facetname === 'centrelevel_1')[0]?.groups.map(group => group.groupname)
    },
    filterSOptions () {
      return this.cluster?.filter(item => item.facetname === 'style')[0]?.groups.map(group => group.groupname)
    },
    filterPROptions () {
      return this.cluster?.filter(item => item.facetname === 'districtlevel_1')[0]?.groups.map(group => group.groupname)
    },
  },
  mounted () {

  },
  methods: {
    onFilter () {
      this.$emit('onFilter', this.searchFilter)
    }
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/css/var.scss";

.search-filter {
  position: relative;
  display: flex;
  align-items: center;
  height: 40rem;
  margin: 0 10rem 0;
  background-color: #fff;
  border-radius: 5rem 5rem 0 0;
  z-index: 2;


  &:after {
    position: absolute;
    left: 0;
    right: 0;
    height: 5rem;
    background-color: #fff;
    z-index: -1;
    content: "";
  }


  &:after {
    border-top: 1px solid #ececee;
    bottom: -5rem;
  }

  .el-select {
    position: static;
    flex: 1;
    border-right: 1px solid #e3e3e3;

    &.is-active {
      :deep() {
        .el-input__inner {
          font-weight: bold;
          color: #dc0600;
        }
      }
    }
  }

  :deep() {
    input::placeholder {
      color: #606266;
    }

    .el-input__inner {
      height: 24rem;
      line-height: 24rem;
      border: 0;
      padding: 0 28rem 0 10rem;
      font-size: 14rem;
      @include clip;

    }

    .el-input__suffix {
      right: 2rem;
    }

    .el-input__icon {
      width: 24rem;
      font-size: 14rem;
      line-height: 24rem;
    }

    .el-popper {
      left: 0 !important;
      right: 0;
    }

    .el-select-dropdown__list {
      padding: 6rem;
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    .el-select-dropdown__item,
    .el-select-dropdown__empty {
      font-size: 14rem;
      height: 30rem;
      padding: 0 8rem;
      line-height: 30rem;
      border-radius: 5rem;
      @include clip;
    }

    .el-select-dropdown__empty {
      padding: 6rem;
      height: 42rem;
    }

    .filter-year {
      .el-select-dropdown__list {
        display: grid;
        grid-template-columns: repeat(4, minmax(0, 1fr));

      }
    }
  }

}
</style>