<!--微信端订阅首页-->
<template>
  <div class="search-tab" v-loading="loading">
    {{ }}
    <swiper :options="swiperOptions" ref="swiperRef">
      <swiper-slide v-for="tab in tabData" :key="tab.id">
        <div class="tab-item" :class="{ 'is-active': tabActive === tab.id }">
          {{ tab.orderName }}
          <div class="_count" v-if="tab.dayRefreshCount > 0">
            {{ tab.dayRefreshCount }}
          </div>
        </div>
      </swiper-slide>
    </swiper>
  </div>
</template>
<script>
import {
  getorderreadnewlist
} from "@/utils/orderreadnew";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
export default {
  name: "tab-bar",
  components: {
    Swiper,
    SwiperSlide,
  },
  data () {
    const self = this
    return {
      swiperOptions: {
        slidesPerView: "auto",
        spaceBetween: 0,
        resistanceRatio: 0,
        on: {
          click: function () {
            self.tabActive = self.tabData[this.clickedIndex].id
            this.slideTo(this.clickedIndex)
          },
        },
      },
      loading: false,
      tabData: [],
      tabActive: 0, //订阅id
    };
  },
  computed: {

  },
  mounted () {
    this.getTabData();
  },
  methods: {
    getTabData () {
      this.loading = true;
      getorderreadnewlist({ PageIndex: 1, PageSize: 999 }).then((res) => {
        if (res.data.data) {
          this.tabData = res.data.data.pageData.items;
          this.tabActive = this.tabData[0]?.id ?? 0
        }
        this.loading = false;
      });
    },
  },
  watch: {
    tabActive (val) {
      this.$emit('onTab', val) //传递订阅词 id
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/css/var.scss";

.search-tab {
  position: relative;
  display: flex;
  align-items: center;
  height: 40rem;
  margin: 0 10rem 0;
  background-color: #fff;
  border-radius: 5rem 5rem 0 0;
  z-index: 2;

  :deep() {
    .swiper-container {
      padding: 0 10rem;
    }

    .swiper-slide {
      width: auto !important;
    }

  }

  .tab-item {
    position: relative;
    height: 40rem;
    padding: 0 10rem;
    font-size: 14rem;
    line-height: 40rem;
    color: #666;

    &.is-active {
      font-weight: bold;
      color: #000;

      &:after {
        position: absolute;
        left: 50%;
        bottom: 0;
        width: 14rem;
        height: 2rem;
        margin-left: -7rem;
        background-color: $colorMain;
        content: "";
      }
    }

    ._count {
      position: absolute;
      right: -4rem;
      top: 2rem;
      min-width: 20rem;
      height: 20rem;
      padding: 0 4rem;
      color: #fff;
      font-size: 12rem;
      line-height: 20rem;
      text-align: center;
      background-color: #d10e00;
      border-radius: 999px;
    }
  }
}
</style>