<template>
  <div>
    <VipHeader v-if="!isHeaderHidden" :class="headerClass" :keyNum="new Date().getTime()"></VipHeader>
    <router-view />
    <VipFooter v-if="!isFooterHidden"></VipFooter>
    <VipTools :ts="new Date().getTime()"></VipTools>
  </div>
</template>
<script>
import VipHeader from '@/components/Public/header'
import VipFooter from '@/components/Public/footer'
import VipTools from '@/components/Public/tools'
import { onMounted } from 'vue';
export default {
  components: { VipHeader, VipFooter, VipTools },
  name: 'App',
  watch: {
    $route (to, from) {
      var tourl = from.fullPath;
      if (tourl.indexOf('Search/Detail') > -1) {
        window.location.reload(); //监测到路由发生跳转时刷新一次页面
      }
    },
  },
  computed: {
    isHeaderHidden () {
      return this.$route.meta.isSingle || this.$route.meta.isMobile || this.$route.meta.isCompare
    },
    isFooterHidden () {
      return this.$route.meta.isSingle || this.$route.meta.isMobile || this.$route.meta.isCompare || this.$route.name === "Chat"
    },


    headerClass () {
      if (this.$route.meta.isIndex || this.$route.name === "Search") {
        return "hasSearcher"
      }
      else {
        return ""
      }
    },
  },
  mounted () {
    console.log('layoutDefault')
  }
}

</script>
<style lang="scss">
#header+#footer {
  top: calc(100vh - 390px);
  border-top: 0;
}
</style>
