<!--微信端订阅首页-->
<template>
  <div class="nav-bars">
    <router-link v-for="nav in navs" :to="nav.path" :key="nav.path" class="tab-item">
      <img :src="nav.icon" />
      <span class="_name">{{ nav.label }}</span>
    </router-link>
  </div>
</template>
<style lang="scss" scoped>
@import "@/assets/css/var.scss";


.nav-bars {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 70rem;
  padding-bottom: 10rem;
  background-color: #fff;
  box-shadow: -2rem 2rem 18rem 4rem rgba(129, 129, 129, .17);

  .tab-item {
    width: 60rem;
    height: 60rem;
  }
}

.tab-item {
  display: flex;
  gap: 4rem;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  img {
    width: 25rem;
    height: 25rem;
  }

  ._name {
    font-size: 12rem;
  }
}

.router-link-active {
  ._name {
    color: $colorMain;
  }
}
</style>
<script>
export default {
  name: "tab-bars",
  data () {
    return {
      tabActive: '',
      navs: [
        {
          label: '首页',
          icon: require('@/assets/images/mobile/tab-icon1.png'),
          path: '/Mobile/index'
        },
        {
          label: '政策列表',
          icon: require('@/assets/images/mobile/tab-icon2.png'),
          path: '/Mobile/ai'
        },
        {
          label: 'AI问政',
          icon: require('@/assets/images/mobile/tab-icon3.png'),
          path: '/Mobile/chat'
        },
        {
          label: '政策订阅',
          icon: require('@/assets/images/mobile/tab-icon4.png'),
          path: '/Mobile/subscribe'
        }
      ]
    };
  },
  computed: {

  },
  mounted () {

  },
  methods: {

  },
};
</script>
