<!--微信端订阅首页-->
<template>
  <div class="search-tab" v-loading="loading">
    <swiper :options="swiperOptions" ref="swiperRef">
      <swiper-slide v-for="tab, index in tabData" :key="index">
        <div class="tab-item" :class="{ 'is-active': tabActive === index }">
          {{ tab.label }}
        </div>
      </swiper-slide>
    </swiper>
  </div>
</template>
<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
export default {
  name: "tab-bar",
  components: {
    Swiper,
    SwiperSlide,
  },
  props: {
    tabData: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false
    },
  },
  data () {
    const self = this
    return {
      swiperOptions: {
        slidesPerView: "auto",
        spaceBetween: 0,
        resistanceRatio: 0,
        on: {
          click: function () {
            self.tabActive = this.clickedIndex
            this.slideTo(this.clickedIndex)
          },
        },
      },
      tabActive: 0,
    };
  },
  computed: {

  },
  mounted () {
    if (Object.keys(this.$route.query).length > 0) {
      const index = this.tabData.map(item => item.label).indexOf(this.$route.query.tab)
      this.tabActive = index
      this.$refs.swiperRef.$swiper.slideTo(index)
    }
  },
  methods: {

  },
  watch: {
    tabActive (val) {
      this.$emit('onTab', val)
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/css/var.scss";

.search-tab {
  position: relative;
  display: flex;
  align-items: center;
  height: 40rem;
  margin: 0 10rem 0;
  background-color: #fff;
  border-radius: 5rem 5rem 0 0;
  z-index: 2;

  :deep() {
    .swiper-container {
      padding: 0 10rem;
    }

    .swiper-slide {
      width: auto !important;
    }

  }

  .tab-item {
    position: relative;
    height: 40rem;
    padding: 0 10rem;
    font-size: 14rem;
    line-height: 40rem;
    color: #666;

    &.is-active {
      font-weight: bold;
      color: #000;

      &:after {
        position: absolute;
        left: 50%;
        bottom: 0;
        width: 14rem;
        height: 2rem;
        margin-left: -7rem;
        background-color: $colorMain;
        content: "";
      }
    }
  }
}
</style>