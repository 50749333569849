<!--微信端订阅首页-->
<template>
  <div class="policy-list" v-loading="loading">
    <el-empty v-if="data.length === 0"></el-empty>
    <div class="list-item" v-for="item, index in data" :key="index">
      <div class="_name">
        <span class="_type">{{ item.style }}</span><router-link :to="`/mobile/policy/${item._id}`">{{ item.title
        }}</router-link>
      </div>
      <div class="_info">
        <span>发文：{{ item.organ }}</span><span>发布：{{ item.publish_time }}</span>
      </div>
      <div class="_targ" v-if="item.theme">
        <span v-for="tag, index in item.theme?.split(';')" :key="index">{{ tag }}</span>
      </div>
    </div>
    <div class="list-more" v-if="data.length > 0" v-loading="loading">
      {{ isAll ? '没有更多内容了！' : '往上拉加载更多！' }}
    </div>
  </div>
</template>
<script>
export default {
  name: "policy-list",
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false
    },
    isAll: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {

    };
  },
  computed: {

  },
  mounted () {

  },
  methods: {

  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/css/var.scss";

.policy-list {
  margin: -10rem 0;

  .list-item {
    display: flex;
    flex-direction: column;
    gap: 8rem;
    padding: 12rem 0 18rem;
    border-bottom: 1px solid #e9f0ff;

    &:last-child {
      border-bottom: 0;
    }

    ._name {
      font-size: 18rem;
      line-height: 28rem;

      ._type {
        font-size: 15rem;
        color: #dc0600;

        &:before {
          content: "【";
        }

        &:after {
          content: "】";
        }
      }
    }

    ._info {
      display: flex;
      gap: 20rem;
      font-size: 12rem;
      color: #a3a3a3;

      span {
        max-width: calc(100% - 180rem);
        @include clips(1);
      }
    }

    ._targ {
      margin-top: 4rem;
      @include clips(1);

      span {
        display: inline-flex;
        line-height: 16rem;
        max-width: 80rem;
        min-width: 40rem;
        height: 16rem;
        margin-right: 10rem;
        padding: 0 8rem;
        font-size: 10rem;
        color: $colorMain;
        background-color: #ffe6dc;
        border-radius: 999px;
        @include clip;
      }
    }
  }
}

.list-more {
  margin: 0 0 -20rem;
  padding: 20rem 0;
  text-align: center;
}
</style>
