<template>
  <div id="page" :class="{ 'has-search': hasSearch }">
    <headBar :hasSearch="hasSearch"></headBar>
    <router-view :key="$route.fullPath" />
    <navBars></navBars>
  </div>
</template>
<script>
import { navBars, headBar } from '@/views/Mobile/components'
export default {
  name: "MIndex",
  components: {
    navBars,
    headBar,
  },
  data () {
    return {
    };
  },
  computed: {
    hasSearch () {
      return true
    }
  },
  beforeCreate () {
    console.log('layoutMobile')
  },
  methods: {
  },
};
</script>
<style lang="scss">
html,
body,
#app {
  @media (max-width: 768px) {
    min-width: auto;
  }
}

html {
  font-size: calc(8 / 30 * 1vw);

  @media (min-width: 768px) {
    font-size: 2.048px;
  }
}

body>iframe {
  display: none;
}
</style>
<style lang="scss" scoped></style>


