import { render, staticRenderFns } from "./headBar.vue?vue&type=template&id=87b19750&scoped=true&"
import script from "./headBar.vue?vue&type=script&lang=js&"
export * from "./headBar.vue?vue&type=script&lang=js&"
import style0 from "./headBar.vue?vue&type=style&index=0&id=87b19750&prod&lang=scss&"
import style1 from "./headBar.vue?vue&type=style&index=1&id=87b19750&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "87b19750",
  null
  
)

export default component.exports