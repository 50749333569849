<!--微信端订阅首页-->
<template>
  <div class="head-bar">
    <!-- <div class="head-bar_left">
      <el-button v-if="!isRoot" @click="goBack()" type="text" icon="el-icon-arrow-left"></el-button>
    </div>
    <div class="head-bar_title">
      <router-link to="/">{{ this.$route.meta.title }}</router-link>

    </div>
    <div class="head-bar_right">
      <el-button v-if="!hasSearch" @click="goSearch()" type="text" icon="el-icon-search"></el-button>
    </div> -->
  </div>
</template>
<script>
export default {
  name: "tab-bars",
  props: {
    hasSearch: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      tabActive: '',
      tabs: [
        {
          label: '首页',
          icon: require('@/assets/images/mobile/tab-icon1.png'),
          path: '/Mobile/index'
        },
        {
          label: '智能智配',
          icon: require('@/assets/images/mobile/tab-icon2.png'),
          path: '/Mobile/ai'
        },
        {
          label: 'AI问政',
          icon: require('@/assets/images/mobile/tab-icon3.png'),
          path: '/Mobile/chat'
        },
        {
          label: '政策订阅',
          icon: require('@/assets/images/mobile/tab-icon4.png'),
          path: '/Mobile/order'
        }
      ]
    };
  },
  computed: {
    isRoot () {
      return this.$route.name === 'mobileIndex' || this.$route.name === 'mobileAi' || this.$route.name === 'mobileChat' || this.$route.name === 'mobileSubscribe'
    },
  },
  mounted () {

  },
  methods: {
    goBack () {
      this.$router.go(-1)
    },
    goSearch () {
      this.$router.push('/Mobile/search')
    }
  },
};
</script>
<style lang="scss">
@import "@/assets/css/mobile2024.scss";
</style>
<style lang="scss" scoped>
@import "@/assets/css/var.scss";

.head-bar {
  display: flex;
  height: 10rem;
  padding: 0;
  color: #fff;
  text-align: center;
  align-items: center;


  &_left {
    width: 44rem;
  }

  &_title {
    flex: 1;
    font-size: 24rem;
    font-family: SourceHanSerifCN-Bold;

    img {
      height: 24rem;
      vertical-align: -5rem;
    }
  }

  &_right {
    width: 44rem;
  }

  .el-button--text {
    width: 44rem;
    height: 44rem;
    padding: 0 !important;
    color: #fff;
    font-size: 24rem;
    line-height: 44rem;
    vertical-align: top;
  }
}
</style>
