<template>
  <div id="header" :key="new Date().getTime()" :class="{ isFixed: fixedTop }" :style="{ left: `-${scrollLeft}px` }">
    <div class="wrapper">
      <div class="logo" @click="$router.push(encodeURI('/'))">
        <img src="@/assets/images/logo.png" alt="" />
      </div>

      <div class="nav">
        <el-menu :default-active="mActive" mode="horizontal" router>
          <template v-for="(item, index) in menudata">
            <el-submenu v-if="item.children" :index="item.path" :key="index" :popper-class="`menu-pop p${index}`"
              :popper-append-to-body="true">
              <template slot="title">
                <i v-if="item.icon" class="icon iconfont" :class="item.icon"></i>
                {{ item.name }}
              </template>
              <el-menu-item v-for="child in item.children" :key="child.index" :index="child.path">{{ child.name
              }}</el-menu-item>
            </el-submenu>

            <el-menu-item v-else-if="item.path == '/KnowledgeBase'" :key="item.index" @click="handleKnowlegebase()"
              target="_blank">
              <template slot="title">
                <i v-if="item.icon" class="icon iconfont" :class="item.icon"></i>
                {{ item.name }}
              </template>
            </el-menu-item>
            <el-menu-item v-else :key="item.index" :index="item.path">
              <template slot="title">
                <i v-if="item.icon" class="icon iconfont" :class="item.icon"></i>
                {{ item.name }}
              </template>
            </el-menu-item>
          </template>
        </el-menu>
        <!-- <div v-if="userType == 1">
          <button @click="goDangAn"><i class="el-icon-edit-outline"></i> 我的档案馆 </button>
        </div> -->
      </div>
      <div class="user-box">
        <!-- <el-button type="text"><svg t="1628220519204"
                 class="icon"
                 viewBox="0 0 1024 1024"
                 version="1.1"
                 xmlns="http://www.w3.org/2000/svg"
                 p-id="2199"
                 width="60"
                 height="60">
              <path d="M889.263 501.221v280.253h-75.452V501.22c0-188.632-140.127-339.537-312.59-339.537-172.463 0-307.2 156.295-307.2 339.537v280.253h-75.453V501.22c0-215.579 156.295-398.821 355.706-414.99V37.727C468.884 16.168 490.442 0 506.61 0c21.557 0 37.726 16.168 37.726 37.726v48.506c188.631 21.557 344.926 199.41 344.926 414.99z m80.842 323.368c0 21.558-16.168 43.116-37.726 43.116H64.674c-21.558 0-37.727-16.168-37.727-43.116 0-21.557 16.169-43.115 37.727-43.115h867.705c21.558 0 37.726 21.558 37.726 43.115zM625.18 867.705c-5.39 43.116-53.895 80.842-107.79 80.842s-97.01-32.336-107.789-80.842h-70.063C350.316 953.937 425.768 1024 517.389 1024s172.464-70.063 177.853-156.295H625.18z"
                    p-id="2200"></path>
            </svg>消息</el-button>
          <el-button type="text"><svg t="1628220549316"
                 class="icon"
                 viewBox="0 0 1024 1024"
                 version="1.1"
                 xmlns="http://www.w3.org/2000/svg"
                 p-id="2341"
                 width="60"
                 height="60">
              <path d="M512 1024A512 512 0 1 1 512 0a512 512 0 0 1 0 1024z m0-68.214a443.786 443.786 0 1 0 0-887.572 443.786 443.786 0 0 0 0 887.572z m13.233-694.036c46.632 0 84.52 12.288 113.585 38.597 29.145 25.443 43.717 60.416 43.717 104.133 0 35.604-9.452 65.457-27.648 88.773-6.537 8.034-27.017 26.939-61.203 56.793a123.116 123.116 0 0 0-30.563 37.809 97.989 97.989 0 0 0-11.736 48.128v12.367h-77.982v-12.367c0-26.94 4.332-50.255 14.494-69.16 9.452-20.322 37.179-50.254 82.313-90.27l12.367-13.784c13.155-16.856 20.323-34.186 20.323-53.169 0-25.521-7.326-45.135-21.19-59.628-14.493-14.573-35.603-21.82-61.912-21.82-32.768 0-56.792 10.162-71.286 31.272-13.154 17.487-19.692 42.3-19.692 74.28h-76.406c0-53.17 15.28-94.602 45.764-125.244 30.878-31.429 73.177-46.71 126.976-46.71z m-12.997 425.905c15.282 0 28.357 4.333 38.597 14.494 10.24 9.452 15.36 21.819 15.36 37.179a51.594 51.594 0 0 1-15.99 37.81 54.508 54.508 0 0 1-37.81 14.493 52.303 52.303 0 0 1-53.168-52.46c0-15.282 5.12-27.57 15.28-37.1a51.2 51.2 0 0 1 37.731-14.416z"
                    p-id="2342"></path>
            </svg>帮助中心</el-button> -->
        <template v-if="logined == true">
          <el-dropdown placement="bottom-start">
            <div class="user-avatar">
              <svg class="icon" style="
                  width: 1em;
                  height: 1em;
                  vertical-align: middle;
                  fill: currentColor;
                  overflow: hidden;
                " viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="826">
                <path
                  d="M347.452 483.4c4.102 4.104 8.835 7.338 14.204 9.709 5.365 2.365 11.047 3.547 17.044 3.547 6.626 0 12.7-1.182 18.226-3.547 5.522-2.37 10.258-5.604 14.203-9.71 3.944-4.1 7.1-8.834 9.467-14.203 2.368-5.366 3.553-11.202 3.553-17.515 0-12.942-4.26-23.592-12.785-31.956-8.521-8.363-19.41-12.55-32.665-12.55-5.997 0-11.679 1.108-17.044 3.315-5.37 2.211-10.101 5.287-14.204 9.235-4.104 3.942-7.338 8.681-9.707 14.2-2.367 5.527-3.549 11.441-3.549 17.756 0 6.313 1.182 12.149 3.55 17.515 2.37 5.37 5.602 10.102 9.707 14.204zM673.646 524.118h-144.87c-6.315 0-10.89 1.577-13.73 4.732-2.842 3.158-4.26 7.103-4.26 11.834 0 4.419 1.339 8.207 4.024 11.365 2.682 3.155 7.178 4.732 13.491 4.732h144.87c6.313 0 10.888-1.578 13.73-4.732 2.841-3.158 4.261-6.946 4.261-11.365 0-4.731-1.341-8.677-4.026-11.834-2.681-3.155-7.179-4.732-13.49-4.732zM440.007 539.502c-3.948-3.944-8.68-7.1-14.203-9.47a43.47 43.47 0 0 0-17.283-3.547h-59.176c-12.312 0-22.726 4.338-31.25 13.017-8.517 8.678-12.78 19.173-12.78 31.485v44.5h147.709v-44.5a43.435 43.435 0 0 0-3.55-17.281c-2.364-5.524-5.522-10.259-9.467-14.204zM671.278 585.19H530.195c-6.312 0-11.048 1.656-14.203 4.97-3.158 3.313-4.735 7.18-4.735 11.597 0 4.42 1.577 8.207 4.735 11.362 3.155 3.158 7.733 4.736 13.73 4.736h142.975c6.313 0 10.97-1.578 13.968-4.736 2.998-3.155 4.497-6.941 4.497-11.362 0-4.418-1.737-8.285-5.21-11.597-3.47-3.313-8.362-4.97-14.674-4.97z"
                  fill="" p-id="827"></path>
                <path
                  d="M512.208 32.034c-264.971 0-479.773 214.802-479.773 479.773S247.237 991.58 512.208 991.58c264.972 0 479.773-214.802 479.773-479.773 0-264.972-214.801-479.773-479.773-479.773z m266.536 609.492c0 8.523-1.577 16.57-4.735 24.147-3.154 7.573-7.492 14.203-13.017 19.883-5.523 5.681-11.995 10.179-19.41 13.493-7.418 3.315-15.227 4.971-23.434 4.971H305.317c-8.207 0-15.94-1.577-23.2-4.734-7.26-3.158-13.573-7.338-18.935-12.547-5.366-5.209-9.628-11.283-12.785-18.226-3.155-6.943-4.732-14.36-4.732-22.25V377.826c0-17.044 5.209-31.01 15.623-41.9 10.414-10.888 25.091-16.332 44.029-16.332h409.99c19.252 0 34.64 4.892 46.158 14.676 11.522 9.784 17.28 24.619 17.28 44.5v262.756z"
                  fill="" p-id="828"></path>
                <path
                  d="M673.646 465.885h-96.11c-4.731 0-8.755 1.34-12.07 4.023-3.315 2.681-4.972 6.232-4.972 10.65 0 4.423 1.656 8.208 4.972 11.363 3.314 3.158 7.338 4.735 12.07 4.735h96.11c4.734 0 8.836-1.577 12.307-4.735 3.472-3.155 5.21-6.94 5.21-11.362 0-4.416-1.738-7.97-5.21-10.65-3.471-2.685-7.573-4.024-12.307-4.024z"
                  fill="" p-id="829"></path>
              </svg>
              <div>
                <div class="user-name" :title="this.userName">
                  {{ this.userName }}
                </div>
                <div class="organ-name" :title="this.organName">
                  {{ this.organName }}
                </div>
              </div>
            </div>
            <el-dropdown-menu v-if="userType == 0" slot="dropdown" class="user-drop">
              <el-dropdown-item v-if="userType == 0" @click.native="handleUserIndex">个人中心</el-dropdown-item>
              <!-- <el-dropdown-item v-if="userType == 0" @click.native="handleVipIndex">会员服务</el-dropdown-item> -->
              <!-- <el-dropdown-item v-if="isBind == 0 && userType == 0 && (issync==0 || issync==null)"
                @click.native="handleBindOrgan(1)">绑定机构</el-dropdown-item>
              <el-dropdown-item v-if="isBind > 0 && userType == 0 && issync>0"
                @click.native="handleBindOrgan(0)">解绑机构</el-dropdown-item> -->

              <el-dropdown-item v-if="userType == 0 && (issync == 0 || issync == null)"
                @click.native="handleBindOrgan(1)">绑定机构</el-dropdown-item>
              <el-dropdown-item v-if="userType == 0 && issync > 0"
                @click.native="handleBindOrgan(0)">解绑机构</el-dropdown-item>

              <el-dropdown-item v-if="userType == 0" @click.native="handleLoginOut">退出</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <el-button-group>
            <el-button v-if="userType == 1" @click.native="handleLoginOutOrg" type="primary">登录</el-button>
          </el-button-group>
        </template>
        <template v-else>
          <el-button @click="handleLogin" type="primary">登录</el-button>
          <el-button @click="handleRegiter">注册</el-button>
        </template>
      </div>
    </div>
    <bindAltOrg ref="bindAltOrg"></bindAltOrg>
  </div>
</template>
<script>
import { GetUserInfo, InstitutionIp, InstitutionBind } from "@/utils/api";
import bindAltOrg from "@/components/bindaltOrg";
import { redirectSSOLogin } from "@/utils/sso-login-utils";
import crypto from "@/utils/crypto";
import { islogin, islogined, isUserLogin } from "@/utils/logincheck";
import {
  redirectSSORegister,
  redirectSSOUserIndex,
  handleSSOLoginOut,
  handleSSOLoginOutOrg,
} from "@/utils/sso-login-utils";

export default {
  components: {
    bindAltOrg,
  },
  props: {
    keyNum: {
      type: Number,
    }
  },
  data () {
    return {
      // isBind: false,
      logined: false,
      input: "",
      navIndex: "1",
      userName: "",
      organName: "",
      userType: 0,
      isBind: 0,
      issync: 0,
      // headImg: require("@/assets/images/avatar-default.png"),
      headImg: require("@/assets/images/user-pic.png"),
      menudata: [
        {
          name: "首页",
          path: "/",
        },
        {
          name: "AI问政",
          path: "/Chat",
        },
        {
          name: "政策订阅",
          path: "/Subscribe",
        },
        {
          name: "政策分析",
          path: "/Analysis",
          children: [
            {
              name: "统计分析",
              path: "/Analysis/YearOfIssue",
            },
            {
              name: "相似条文查询",
              path: "/Clause",
            },
            {
              name: "政策解读",
              path: "/Interpretation",
            },
          ],
        },
        {
          name: "政策导航",
          path: "/Navigation",
          icon: "icon-lanmu",
          children: [
            {
              name: "发文机构导航",
              path: "/Navigation/Institution",
            },
            {
              name: "产业分类导航",
              path: "/Navigation/Industry",
            },
          ],
        },

        // {
        //   name: "政策专题",
        //   path: "/Special/Index",
        // },

        {
          name: "政策知识库",
          path: "/KnowledgeBase",
          icon: "icon-baibaoxiang",
        },
      ],
      scrollOld: 0,
      fixedTop: false,
      scrollLeft: 0,
    };
  },
  computed: {
    mActive () {
      return this.$route.path;
    },
  },
  watch: {
    keyNum () {
      if (islogined()) {
        this.handleUserInfo();
      }
    },
  },
  created () {
    if (islogined()) {
      this.handleUserInfo();
    }
  },
  mounted () {
    let that = this;
    // 监听localhostStorage的数据变化，结合utils/tool.js配合使用
    window.addEventListener("setItemEvent", function (e) {
      if (e.key && e.key == "_user" && e.newValue) {
        that.logined = true;
        let uInfo = JSON.parse(JSON.parse(crypto.decrypt(e.newValue)).data);
        that.userName = uInfo.chanChuangUserInfo.nickname;
        that.organName = uInfo.chanChuangUserInfo.institution;
        that.userType = uInfo.chanChuangUserInfo.type;
        that.isBind = uInfo.chanChuangUserInfo.institutionId;

        if (
          uInfo.chanChuangUserInfo.avatarUrl != null &&
          uInfo.chanChuangUserInfo.avatarUrl != ""
        ) {
          // that.headImg = "https://fmh.cqvip.com/download/" + uInfo.headImage;
          that.headImg = uInfo.chanChuangUserInfo.avatarUrl;
        } else {
          that.headImg = require("@/assets/images/avatar-default.png");
        }
      }
      if (e.key === "_UIF") {
        //获取头像
        // console.log("listener",JSON.parse(that.$Common.getStorage("_user")))
        if (that.$Common.getStorage("_user")) {
          let headimage = JSON.parse(that.$Common.getStorage("_user")).chanChuangUserInfo
            .avatarUrl;
          if (headimage != null && headimage != "") {
            // that.headImg = "https://fmh.cqvip.com/download/" + headimage;
            that.headImg = headimage;
          } else {
            that.headImg = require("@/assets/images/avatar-default.png");
          }

          let uInfo = JSON.parse(that.$Common.getStorage("_user"));
          that.userName = uInfo.chanChuangUserInfo.nickname;
          that.organName = uInfo.chanChuangUserInfo.institution;
          that.userType = uInfo.chanChuangUserInfo.type;
          that.isBind = uInfo.chanChuangUserInfo.institutionId;
        }
      }
    });
    window.addEventListener("scroll", this.handleScroll, true);
  },
  methods: {
    handleUserInfo () {
      console.log('handleUserInfo')
      let that = this;
      let userTemp = JSON.parse(this.$Common.getStorage("_user"));
      console.log(userTemp)
      if (userTemp != null && userTemp != undefined) {
        this.logined = true;
        if (
          userTemp.chanChuangUserInfo.avatarUrl != null &&
          userTemp.chanChuangUserInfo.avatarUrl != ""
        ) {
          this.headImg = userTemp.chanChuangUserInfo.avatarUrl;
          //this.headImg = "https://fmh.cqvip.com/download/" + userTemp.headImage;
        } else {
          this.headImg = require("@/assets/images/avatar-default.png");
        }
        this.userName = userTemp.chanChuangUserInfo.nickname;
        this.organName = userTemp.chanChuangUserInfo.institution;
        this.userType = userTemp.chanChuangUserInfo.type;
        this.isBind = userTemp.chanChuangUserInfo.institutionId;
        this.issync = userTemp.institutionid;
        // if (userTemp.chanChuangUserInfo.type == 1) {
        //   this.menudata = this.menudata.filter(
        //     (item) => item.name != "我的档案馆"
        //   );
        //   //this.menudata.props(this.obj);
        // }
      } else {
        //console.log(this.$Common.getStorage("_login", false));
        this.logined = false;

        let _login = this.$Common.getStorage("_login", false);
        console.log(_login)
        if (_login != "1" && _login != null && _login != undefined) {
          GetUserInfo().then((res) => {
            if (res.data.apiStatusCode === 1000) {
              this.logined = true;

              this.userInfo = res.data.data.userCenterInfo;
              that.userName = this.userInfo.chanChuangUserInfo.nickname;
              that.organName = this.userInfo.chanChuangUserInfo.institution;
              that.userType = this.userInfo.chanChuangUserInfo.type;
              that.isBind = this.userInfo.chanChuangUserInfo.institutionId;
              that.issync = userTemp.institutionid;
              // this.userInfo.headImage = this.userInfo.headImage.replace(
              //   "https://fmh.cqvip.com/download/",
              //   ""
              // );
              if (
                this.userInfo.chanChuangUserInfo.avatarUrl != null &&
                this.userInfo.chanChuangUserInfo.avatarUrl != ""
              ) {
                // that.headImg =
                //   "https://fmh.cqvip.com/download/" + this.userInfo.headImage;
                that.headImg = this.userInfo.chanChuangUserInfo.avatarUrl;
              } else {
                that.headImg = require("@/assets/images/avatar-default.png");
              }
              if (this.userInfo.chanChuangUserInfo.type == 0) {
                this.menudata.push(this.obj);
              }
              this.$Common.setStorage(
                "_user",
                JSON.stringify(this.userInfo),
                60 * 1000 * 1440 * 30
              );
              this.$Common.setStorage("_login", "0", 60 * 1000 * 1440 * 30, false);
            } else {
              this.$Common.setStorage("_login", "1", 60 * 1000 * 1440 * 30, false);
            }
          });
        }
      }
    },
    goDangAn () {
      this.$refs.bindAltOrg.init("");
    },
    async handleKnowlegebase () {
      if (! await isUserLogin()) {
        this.$refs.bindAltOrg.init("");
        return;
      }
      this.$router.push({
        path: "/KnowledgeBase",
      });
    },
    //登录跳转
    handleLogin () {
      //获取当前页面url
      this.$Common.setStorage("wechatpath", {
        wechatredrictpath: this.$route.fullPath,
      });
      redirectSSOLogin();
      //this.$router.push(encodeURI("/User/Login"));
    },
    //注册跳转
    handleRegiter () {
      redirectSSORegister();
      // this.$router.push(encodeURI("/User/Register"));
    },
    //个人中心跳转
    handleUserIndex () {
      redirectSSOUserIndex();
      // this.$router.push(encodeURI("/User/Index"));
    },
    handleVipIndex () {
      this.$router.push(encodeURI("/Member/MemService"));
    },
    lgGetUserInfo () {
      GetUserInfo().then((res) => {
        if (res.data.apiStatusCode === 1000) {
          this.userInfo = res.data.data.userCenterInfo;
          this.organName = this.userInfo.chanChuangUserInfo.institution;
          this.isBind = this.userInfo.chanChuangUserInfo.institutionId;
          this.issync = this.userInfo.institutionid;
          this.$Common.setStorage("_UIF", JSON.stringify(this.userInfo));
          this.$Common.setStorage(
            "_user",
            JSON.stringify(this.userInfo),
            60 * 1000 * 1440 * 30
          );
        }
      });
    },
    handleBindOrgan (type) {
      if (type == 1) {
        InstitutionIp().then((res) => {
          if (res.data.data != null && res.data.data != 0) {
            InstitutionBind({ institutionId: 1 }).then((info) => {
              if (info.data.data != null && info.data.data != 0) {
                this.$MessageBox.MessageError(info.data.message);
              } else {
                this.lgGetUserInfo();
                this.$MessageBox.MessageSuccess(info.data.message);
                //location.reload();
              }
            });
          } else {
            this.$MessageBox.MessageError(res.data.message);
          }
        });
      } else if (type == 0) {
        InstitutionBind().then((info) => {
          if (info.data.data != null && info.data.data != 0) {
            this.$MessageBox.MessageError(info.data.message);
          } else {
            this.lgGetUserInfo();
            this.$MessageBox.MessageSuccess(info.data.message);
            //location.reload();
          }
        });
      }
    },
    handlePolicyNav () {
      this.$router.push(encodeURI("/Search/PolicyNav"));
    },
    //退出登录
    handleLoginOut () {
      handleSSOLoginOut();
      // Loginout().then((res) => {
      //   if (res.data.apiStatusCode == 1000) {
      //     localStorage.removeItem("_user");
      //     localStorage.removeItem("_tokenKey");
      //     localStorage.removeItem("_refreshTokenKey");
      //     localStorage.removeItem("_MKA");
      //     localStorage.removeItem("_UIF");
      //     // localStorage.removeItem("_HID")
      //     this.logined = false;
      //     this.headImg = require("@/assets/images/user-pic.png");
      //     // this.$forceUpdate();
      //     //跳转到登录
      //     this.$router.push(encodeURI("/User/Login"));
      //   }
      // });
    },
    //退出登录
    handleLoginOutOrg () {
      handleSSOLoginOutOrg();
    },
    //头部固定
    handleScroll () {
      const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
      if (document.querySelector("#header")) {
        const headerHeight = document.querySelector("#header").offsetHeight;
        if (this.scrollOld > scrollTop && !this.fixedTop && scrollTop > headerHeight) {
          this.fixedTop = true;
        }
        if (this.scrollOld < scrollTop && this.fixedTop) {
          this.fixedTop = false;
        }
        this.scrollLeft = document.documentElement.scrollLeft || document.body.scrollLeft;
        if (scrollTop === 0) {
          this.scrollLeft = 0;
          this.fixedTop = false;
        }
        this.scrollOld = scrollTop;
      }
    },
  },
};
</script>
