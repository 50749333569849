<!--微信端订阅首页-->
<template>
  <div class="search-bar">
    <el-input v-model="input" placeholder="请输入关键词" clearable @keyup.enter.native="onSearch()"></el-input>
    <el-button type="primary" @click="onSearch">搜索</el-button>
  </div>
</template>
<script>
export default {
  name: "search-bar",
  data () {
    return {
      input: '',
    };
  },
  computed: {

  },
  mounted () {
    if (this.$route.query.input) {
      this.input = this.$route.query.input
    }
  },
  methods: {
    onSearch () {
      console.log('onSearch')
      if (this.input === '') {
        this.$message({
          type: 'warning',
          message: '请输入搜索的内容！',
          customClass: 'mobile-message'
        })
      }
      else {
        console.log(this.$route.name)
        if (this.$route.name !== 'mobileSearch') {
          this.$router.push({
            name: 'mobileSearch',
            query: {
              input: this.input
            }
          })
        }
        else {
          this.$emit('onSearch', this.input)
          if (this.input === this.$route.query.input) return
          this.$router.replace({
            name: 'mobileSearch',
            query: {
              input: this.input
            }
          })

        }
      }
    }
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/css/var.scss";

.search-bar {

  display: flex;
  height: 40rem;
  min-height: 40rem;
  margin: 0 10rem;
  padding: 4rem;
  background-color: #fff;
  border-radius: 5rem !important;
  align-items: center;
  box-shadow: -2rem 2rem 18rem 4rem rgba(129, 129, 129, .17);
  z-index: 1;

  :deep() {
    .el-input__inner {
      height: 20rem;
      font-size: 15rem;
      padding: 0 12rem;
      line-height: 20rem;
      background-color: transparent;
      border: 0;
    }

    .el-input__clear {
      font-size: 14rem;
      line-height: 20rem;
    }
  }

  .el-button {
    width: 70rem;
    height: 32rem;
    padding: 0 !important;
    font-size: 14rem;
    border-radius: 5rem;
  }
}
</style>