<template>
  <div id="app" :class="{ 'moblie': isMoblie }">
    <component :is="layout" :key="$route.name"></component>
  </div>
</template>
<style>
#header+#footer {
  top: calc(100vh - 390px);
  border-top: 0;
}
</style>
<script>
import { LayoutDefault, LayoutMobile } from '@/layouts'
export default {
  components: { LayoutDefault, LayoutMobile },
  name: 'App',
  watch: {
    $route (to, from) {
      var tourl = from.fullPath;
      if (tourl.indexOf('Search/Detail') > -1) {
        window.location.reload(); //监测到路由发生跳转时刷新一次页面
      }
    },
  },
  computed: {
    layout () {
      return !this.$route.name?.includes('mobile') ? LayoutDefault : LayoutMobile
    },
    isMoblie () {
      return this.$route.name?.includes('mobile')
    }
  },

}
</script>

