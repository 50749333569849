<template>
  <div id="footer">
    <div class="wrapper">
      <div class="left">
        <div class="Slogan">探索政策智慧 发现无限可能</div>
        <div>
          <h4><i class="icon iconfont icon-youqinglianjie"></i>友情链接：</h4>

          <ul>
            <li><a href="https://di.vipisall.com/" target="_blank">智启科创平台</a></li>
            <li><a href="https://ai.vipisall.com/" target="_blank">科创助手</a></li>
          </ul>
        </div>
        <div>
          <h4><i class="icon iconfont icon-lianxiwomen1"></i>联系我们：</h4>
          <p>
            <span>地址：中国·重庆两江新区</span><span>邮编：400000</span
            ><span>电话：023-67033367（技术支持）</span
            ><span>023-63505712（业务支持）</span>
          </p>
        </div>
      </div>
      <div class="right">
        <div class="code">
          <img src="@/assets/images/code.jpg" alt="" />
          微信扫码关注，政策资讯快人一步。
        </div>
      </div>
    </div>
    <div class="bottom">
      <div class="wrapper">
        <div>
          <p>
          COPYRIGHT © 2022 重庆维普资讯有限公司 保留所有权利
          <a href="http://www.beian.gov.cn" target="_blank">渝B2-20050021-14</a>
          <a
            href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=50019002503587&amp;from=index"
            target="_blank"
            >渝公网安备 50019002503587</a
          >
        </p>
        <p>建议使用360(极速模式)、firefox、谷歌浏览器</p>
        </div>
        <div class="right">
          <span class="sp">重庆市出版专项资金资助</span>
          <a
          href="https://xyt.xcc.cn/getpcInfo?sn=1628311941464072192&language=CN&certType=8&url=*.vipisall.com"
          target="_blank"
          style="position: relative; display: inline-block; height: 38px"
        >
          <div
            style="width: 100%; height: 100%; position: absolute; top: 0; left: 0"
          ></div>
          <embed
            src="https://program.xinchacha.com/web/1628311941464072192=*.vipisall.com.svg"
            width="103"
            height="38"
            type="image/svg+xml"
            pluginspage="//www.adobe.com/svg/viewer/install/"
          />
        </a>
        </div>


      </div>
    </div>
    <el-dialog title="用户协议" :visible.sync="agreementVisible" width="900px">
      <VipAgreement />
    </el-dialog>
    <el-dialog title="隐私政策" :visible.sync="privacyVisible" width="900px">
      <VipPrivacy />
    </el-dialog>
  </div>
</template>
<script>
import VipAgreement from "@/components/agreement";
import VipPrivacy from "@/components/privacy";
export default {
  components: { VipAgreement, VipPrivacy },
  data() {
    return {
      privacyVisible: false,
      agreementVisible: false,
    };
  },
};
</script>
